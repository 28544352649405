<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Name History</h3>

    <PreferredNameYN
        v-model="form.profilePreferredNameYN"
    />
    <div v-if="form.profilePreferredNameYN" class="question-box with-arrow">
      <PreferredName
          v-model="form.profilePreferredName"
      />
    </div>

    <PreviousNameYN
        v-model="form.profilePreviousNameYN"
    />
    <div v-if="form.profilePreviousNameYN" class="question-box with-arrow">
      <PreviousName
          v-model="form.profilePreviousName"
      />
      <PreviousNameDate
          v-model="form.profilePreviousNameChangedDate"
      />
      <PreviousNameMethod
          v-model="form.profilePreviousNameChangedMethod"
      />
    </div>

    <AliasNameYN
        v-model="form.profileAliasYN"
    />
    <div v-if="form.profileAliasYN" class="question-box with-arrow">
      <AliasName
          v-model="form.profileAlias"
      />
    </div>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import { statusOptions } from "../../../question/options/statusOptions";

import PreviousName from "../../../question/questions/clientProfile/name/PreviousName";
import PreviousNameYN from "../../../question/questions/clientProfile/name/PreviousNameYN";
import PreviousNameDate from "../../../question/questions/clientProfile/name/PreviousNameDate";
import PreviousNameMethod from "../../../question/questions/clientProfile/name/PreviousNameMethod";
import PreferredNameYN from "../../../question/questions/clientProfile/name/PreferredNameYN";
import PreferredName from "../../../question/questions/clientProfile/name/PreferredName";
import AliasNameYN from "../../../question/questions/clientProfile/name/AliasNameYN";
import AliasName from "../../../question/questions/clientProfile/name/AliasName";


export default {
  name: 'NameHistory',
  mixins: [fieldsetHelpers, statusOptions],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    AliasName,
    AliasNameYN,
    PreferredName,
    PreferredNameYN,
    PreviousNameMethod,
    PreviousNameDate,
    PreviousNameYN,
    PreviousName,
  },
  data() {
    return {
      label: 'Name History' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
