<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Nationality</h3>

    <Nationality
        v-model="form.profileNationality"
    />

    <div v-if="form.profileNationality && !['British'].includes(form.profileNationality)"
         class="question-box with-arrow">
      <IndefiniteLeave
          v-model="form.profileIndefiniteLeave"
      />
      <template v-if="form.profileIndefiniteLeave === false">
        <VisaType
            v-model="form.profileVisaType"
        />
        <UKSince
            v-model="form.profileUKSince"
        />
        <VisaExpiry
            v-model="form.profileVisaExpiry"
        />
      </template>
      <CountryBorn
          v-model="form.profileCountryBorn"
      />
    </div>

    <DiplomaticImmunity
        v-model="form.profileDiplomaticImmunity"
    />

  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import {statusOptions} from "../../../question/options/statusOptions";

import DiplomaticImmunity from "../../../question/questions/clientProfile/status/DiplomaticImmunity";
import Nationality from "../../../question/questions/clientProfile/status/Nationality";
import IndefiniteLeave from "../../../question/questions/clientProfile/status/IndefiniteLeave";
import UKSince from "../../../question/questions/clientProfile/status/UKSince";
import VisaExpiry from "../../../question/questions/clientProfile/status/VisaExpiry";
import CountryBorn from "../../../question/questions/clientProfile/status/CountryBorn";
import VisaType from "@/components/common/questionnaires/question/questions/clientProfile/status/VisaType";

export default {
  name: 'NationalityStatus',
  mixins: [fieldsetHelpers, statusOptions],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    VisaType,
    CountryBorn,
    VisaExpiry,
    UKSince,
    IndefiniteLeave,
    Nationality,
    DiplomaticImmunity
  },
  data() {
    return {
      label: 'Nationality' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
