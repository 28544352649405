<template>
  <QuestionnaireBase
      :title="title"
      :form="form"
      :finishFunction="null"
      :showNavigationButtons="true"
  >
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "@/views/questionnaires/loadHelpers";

import QuestionnaireBase from "../../../../components/common/questionnaires/questionnaire";
import NameHistory from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/NameHistory";
import NationalityStatus from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/NationalityStatus";
import Pets from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/Pets";
import Income from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/Income";

export default {
  name: "EstatePlanningFactFindSimpleAdditional",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    NameHistory,
    NationalityStatus,
    Pets,
    Income
  },
  data() {
    return {
      name: 'EstatePlanningFactFindSimpleBasic',
      title: "Additional",
      url: 'fact_find_full',
      order: [ // imported components required here to load
        'NameHistory',
        'NationalityStatus',
        'Income',
        'Pets'
      ],
    };
  },
};
</script>
