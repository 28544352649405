<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Income</h3>

    <PrimaryEmployment
        v-model="form.profilePrimaryEmployment"
    />

    <SecondaryEmploymentYN
        v-model="form.profileSecondaryEmploymentYN"/>

    <SecondaryEmployment
        v-if="form.profileSecondaryEmploymentYN"
        v-model="form.profileSecondaryEmployment"
    />

    <RetirementAge
        v-model="form.profileRetirementAge"
    />

  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";

import PrimaryEmployment
  from "@/components/common/questionnaires/question/questions/clientProfile/income/PrimaryEmployment";
import SecondaryEmployment
  from "@/components/common/questionnaires/question/questions/clientProfile/income/SecondaryEmployment";
import SecondaryEmploymentYN
  from "@/components/common/questionnaires/question/questions/clientProfile/income/SecondaryEmploymentYN";
import RetirementAge from "@/components/common/questionnaires/question/questions/clientProfile/income/RetirementAge";

export default {
  name: 'Income',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    RetirementAge,
    SecondaryEmploymentYN,
    SecondaryEmployment,
    PrimaryEmployment
  },
  data() {
    return {
      label: 'Income' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
