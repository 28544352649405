<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Pets</h3>

    <PetsYN v-model="form.pets" />
  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import { statusOptions } from "../../../question/options/statusOptions";

import PetsYN from "@/components/common/questionnaires/question/questions/clientProfile/status/PetsYN";

export default {
  name: 'Pets',
  mixins: [fieldsetHelpers, statusOptions],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    PetsYN
  },
  data() {
    return {
      label: 'Pets' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
